@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 255 40% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 2% 11%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    -webkit-tap-highlight-color: transparent;
  }
}

@layer components {
  .fullHeight {
    @apply min-h-[calc(100vh-94px)];
  }
  .button {
    @apply p-2 px-4 bg-black/60 mx-5 cursor-pointer rounded-3xl border-none text-white font-medium hover:bg-white hover:text-black transition duration-200;
  }
  .framerButton {
    @apply p-2 px-4 cursor-pointer font-bold text-xl hover:opacity-80 transition-opacity duration-75;
  }
  .customInput {
    @apply bg-gray-300/60 hover:bg-transparent focus:bg-transparent  hover:ring-4 focus-visible:ring-4 transition-all cursor-pointer;
  }
  .responsiveHeading {
    @apply text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl;
  }
  .responsiveHeading2 {
    @apply text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl;
  }
  .responsiveHeading3 {
    @apply text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl;
  }
  .responsiveHeading4 {
    @apply text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl;
  }
  .responsiveHeading5 {
    @apply text-lg sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl;
  }

  .responsiveText {
    @apply text-[calc(0.390625vw+13px)];
  }
  .responsiveText2 {
    @apply text-[calc(0.390625vw+10px)];
  }
  .responsiveText3 {
    @apply text-[calc(0.390625vw+8px)];
  }
  .responsiveText4 {
    @apply text-[calc(0.390625vw+5px)];
  }
}

::-webkit-scrollbar {
  /* width: 0px;  */
  width: 8px;
}

::-webkit-scrollbar-thumb {
  /* background-color: transparent;  */
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}
